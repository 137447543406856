import axios from "axios";
import { config } from "./Config";

export const axiosClient = axios.create();

axiosClient.defaults.headers = config.headers;

const AppService = {
  
  get: function Get(url, config) {
    
    return axiosClient
      .get(url, config)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  },

  post: function Post(url, data, config) {
   
    return axiosClient
      .post(url, data, config)
      .then((response) => {            
        return response;
      })
      .catch((error) => {              
        if (error?.response) return error.response;        
        return error;
        
      });
  },
};
export default AppService;
