import React from "react";
import { HashRouter } from "react-router-dom";
import { AuthContextProvider } from "./contexts/AuthContextProvider";
import { LoadingContextProvider } from "./contexts/LoadingContextProvider";
import { GeneralRoutes } from "./routes/GeneralRoutes";

export default function App() {
  return (
    <HashRouter>
      <LoadingContextProvider>
        <AuthContextProvider>
          <GeneralRoutes>
            {(routeProps) => <GeneralRoutes routeProps={routeProps} />}
          </GeneralRoutes>
        </AuthContextProvider>
      </LoadingContextProvider>
    </HashRouter>
  );
}
