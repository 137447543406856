import React from "react";
import { Modal, Tabs, Button } from "@vwfs-bronson/bronson-react";
import { copyToClipboard } from "../utils.js";

export default function ModalTabs({ params, tabs, handleCloseModal }) {

   
    return (
      <React.Fragment>
        <Modal
            key={params.id}
          buttonConfirmLabel={null}
          buttonConfirmText={null}
          buttonConfirmType={params.ConfirmType}
          center
          id={params.Id}
          onCancel={function noRefCheck() {}}
          onClickOutside={function noRefCheck() {}}
          onClose={handleCloseModal}
          shown
          title={params.Title}
        >
            {
                (tabs.length > 0 &&
                <Tabs defaultSelected={params.defaultTab}>
                    <Tabs.Header>
                        {
                            tabs.map((x) => (
                                <Tabs.HeaderItem key={x.Id} tabId={x.Id} aria-labelledby={x.Id}>
                                    {x.HeaderName}
                                </Tabs.HeaderItem>
                            ))
                        }
                    </Tabs.Header>
                    <Tabs.Content>
                        {
                            tabs.map((x) => (
                                <Tabs.ContentItem key={x.Id} tabId={x.Id} aria-labelledby={x.Id}>
                                    {x.Value !== null && (
                                        <React.Fragment>
                                        <p>{x.Value}</p>
                                        {x.ButtonAction != null && (
                                            <Button onClick={x.ButtonAction}>
                                            {x.ButtonText}
                                          </Button>
                                        )}
                                      </React.Fragment>
                                    )}
                                </Tabs.ContentItem>
                            ))
                        }
                    </Tabs.Content>
                </Tabs>
                )
            }
        </Modal>
      </React.Fragment>
    );
  }
  