import { useState } from "react";
import { useEffect } from "react";

import { Pagination } from "@vwfs-bronson/bronson-react";

const usePagination = (totalItems,currentPage, setCurrentPage, paginationSize = 10, totalPagesView = 4) => {
    // const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(Math.ceil(totalItems / paginationSize));

    const [displayItems, setDisplayItems] = useState(null);

    const changePage = (e, num) => {
        e.preventDefault();
        if (currentPage === num) return;
        setCurrentPage(num);
    };

    useEffect(() => {
        setTotalPages(Math.ceil(totalItems / paginationSize));
    }, [totalItems, paginationSize]);

    useEffect(() => {
        if (totalPages) {
            let res = [];
            let startCut = currentPage;
            let endCut = totalPagesView;
            if(totalPages > totalPagesView){
                if(currentPage === 1){
                    startCut = currentPage;
                }else if(currentPage === totalPages){
                    startCut = totalPages - totalPagesView +1;
                    endCut = totalPages;
                }else{

                    if(currentPage - Math.ceil(totalPagesView/2) < 1){
                        startCut = 1;
                    }else if(currentPage + Math.ceil(totalPagesView/2) > totalPages){
                        startCut = totalPages - totalPagesView + 1;
                        endCut = totalPages;
                    }else{
                        startCut = currentPage - Math.floor(totalPagesView/2);
                        endCut = startCut + totalPagesView - 1;
                    }

                    // totalCut = (currentPage + Math.ceil(totalPagesView/2) > totalPages) 
                    // ? currentPage + (totalPages - currentPage) 
                    // : (currentPage + totalPagesView/2);

                    // startCut = ((currentPage - Math.ceil(totalPagesView/2)) !== 0) 
                    // ? (currentPage + Math.ceil(totalPagesView/2) > totalPages) 
                    // ? currentPage - totalPagesView + (totalPages - currentPage) + 1
                    // : (currentPage - Math.ceil(totalPagesView/2)) + 1
                    // : 1;
                }
            }

            if(totalPages > totalPagesView){
                for (let index = startCut; index <= endCut; index++) {
                    res.push(index);
                }
            }else{
                for (let index = 1; index <= totalPages; index++) {
                    res.push(index);
                }
            }
            
            setDisplayItems(
                <Pagination
                prevDisabled={true}
                nextDisabled={true}
                >
                    <>
                    {
                        currentPage > 2 &&
                        <>
                            <Pagination.Item
                                key={`PaginationItem_${0}`}
                                active={false}
                                onClick={(e) => {
                                    changePage(e, 1);
                                }}
                                className={currentPage === 1 ? 'u-visually-hidden' : ''}
                                data-attr={currentPage}  
                            > {"<<"}   
                            </Pagination.Item>
                            
                        </>
                    }
                    {
                        currentPage !== 1 &&
                        <> 
                                <Pagination.Item
                                key={`PaginationItem_${currentPage - 1}`}
                                active={false}
                                onClick={(e) => {
                                    changePage(e, currentPage - 1);
                                }}
                                className={currentPage === 1 ? 'u-visually-hidden': ''}
                            >
                                 {"<"}   
                                </Pagination.Item>
                                </>
                       
                    }
                            
                        {res?.map((x) => (
                            <Pagination.Item
                                key={`PaginationItem_${x}`}
                                active={x === currentPage}
                                onClick={(e) => {
                                    changePage(e, x);
                                }}
                            >
                                {x}
                            </Pagination.Item>
                            
                        ))}
                        {
                        currentPage !== totalPages &&
                        <>
                         <Pagination.Item
                                key={`PaginationItem_${currentPage + 1}`}
                                active={false}
                                onClick={(e) => {
                                    changePage(e, currentPage + 1);
                                }}
                                className={currentPage === totalPages ? 'u-visually-hidden': ''}
                            >
                                 {">"}   
                                </Pagination.Item>
                            <Pagination.Item
                                key={`PaginationItem_${totalPages+1}`}
                                active={false}
                                onClick={(e) => {
                                    changePage(e, totalPages);
                                }}
                                className={currentPage === totalPages ? 'u-visually-hidden': ''}
                            >
                                {">>"}   
                            </Pagination.Item>
                            </>
                       
                    }
                       
                    </>
                </Pagination>
            );
        }
    }, [totalPages, currentPage]);

    return {
        current: currentPage,
        display: displayItems,
    };
};

usePagination.defaultProps = {
    totalItems: 0,
    paginationSize: 10,
};

export default usePagination;