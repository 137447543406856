import { useCallback, useState } from "react";

export const useLoading = (initState = false, initMessage = null) => {
  const [isLoading, setIsLoading] = useState(initState);
  const [message, setMessage] = useState(initMessage);

  const start = useCallback((message = null) => {
    setIsLoading(true);
    setMessage(message);
  }, []);

  const stop = useCallback((message = null) => {
    setIsLoading(false);
    setMessage(message);
  }, []);

  return [
    { message, isLoading },
    { start, stop },
  ];
};
