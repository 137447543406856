import AppService from "./AppService";

export async function Login(body) {
  var queryString = process.env.REACT_APP_URL_LOGIN;
  
  var headers = {};
  headers[process.env.REACT_APP_AWS_APIKEY_NAME] =
    process.env.REACT_APP_AWS_APIKEY_VALUE;
  var config = {
    headers: headers,
  };
  
  return AppService.post(queryString, body, config)
    .then((response) => {       
      if (response && response.data && response.status === 200) {      
        if (response.data) {           
          return response.data;
        }              
      }
    })
    .catch(function (error) {});
}
