import React from "react";
import { useNavigate } from "react-router-dom";
import { Header, Logo } from "@vwfs-bronson/bronson-react";

export const HeaderComponent = () => {
  const navigate = useNavigate();
  return (
    <Header
      staticHeader
      logo={
        <Logo
          ariaLabel="Logo"
          tabIndex={0}
          title="DigitalServices"
          onClick={function () {
            navigate("/");
          }}
        />
      }
    ></Header>
  );
};
