import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Initializer from "../hoc/Initializer";
import { ErrorPage } from "../pages/ErrorPage";
import { AuthPage } from "../pages/AuthPage";
import { SearchPage } from "../pages/SearchPage2";

export const GeneralRoutes = () => {
  return (
    <Routes>
      <Route element={<Initializer />}>
        <Route path="/error" element={<ErrorPage />} />
        <Route
          path="/search"
          element={
              <SearchPage />
          }
        />
        <Route path="/" element={<AuthPage />} />
        <Route path="*" element={<Navigate to={"/error"} replace />} />
      </Route>
    </Routes>
  );
};
