import React, { useEffect, useState } from "react";
import { FormField,DatePicker, Input, Layout, Select } from "@vwfs-bronson/bronson-react";

export const FormFieldVWFS = ({ size, sizexs, id, 
    label, type, confDatePicker, onchange, items, placeholder, defaultItem, autoComplete }) => {
    return (
        <>
            <Layout.Item default={size} xs={sizexs}>
                <label>{label}</label>
                {/* <FormField labelForId={id} labelText={label} type={type === "datepicker" ? "other": type}> */}
                    {
                        (type === "datepicker") ?
                            <DatePicker
                                onChange={onchange}
                                locale={confDatePicker.locale}
                                disableDays={confDatePicker.disableDays}
                                id={id}
                                dateFormat={confDatePicker.dateFormat}
                                name={id}
                                autoComplete={autoComplete} 
                            />
                        :
                        (type === "select") ?
                            <Select placeholder={placeholder} name={id} id={id} onChange={onchange} defaultValue={defaultItem}>
                                <Select.Item key="0" value="0" children="Sin paginación" />
                                {
                                    items.length && items.map(res => (
                                        <Select.Item key={res.key} value={res.Value} children={res.Text} />
                                    ))
                                }
                            </Select>
                        :
                            <Input id={label} onChange={onchange} name={id} autoComplete={autoComplete}  />
                    }
                    
                {/* </FormField> */}
            </Layout.Item>
        </>
    )

};