import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Layout,
  Button,
  ButtonContainer,
  Card,
  Form,
  FormHeading,
  Fieldset,
} from "@vwfs-bronson/bronson-react";
import ModalComponent from "../components/Modal";
import { FormFieldVWFS } from "../components/FormFieldVWFS";
import { Grid } from "../components/Grid";
import { useLoading } from "../hooks/useLoading";
import { useForm } from "../hooks/useForm";
import { SearchLog } from "../services/SearchService";
import ModalTabs from "../components/ModalTabs";
import { copyToClipboard } from "../utils";

export const SearchPage = () => {
  const [endDate, setEndDate] = useState();
  const [defaultSelected] = useState("25");
  const [startDate, setStartDate] = useState();
  const [{ isLoading }, { start, stop }] = useLoading(false);
  const [data, setData] = useState(null);
  const [canSearch, setCanSearch] = useState(false);
  const [pageNumber, setPageNumber] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);
  const [filters, handleOnChange] = useForm({
    Usuario: "",
    application: "",
    service: "",
    method: "",
    maxregisters: defaultSelected,
  });

  function handleOpenModal(e) {
    setSelectedModal(data.LogItems[e]);
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setPageNumber(1);
    setCanSearch(true);
  };

  const fetchdata = async () => {
    try {
      start();
      //debugger;
      var json = {
        User: filters.user,
        Service: filters.service,
        Method: filters.method,
        Application: filters.application,
        StartDate: moment(startDate, "DD-MM-YYYY").format(),
        EndDate: moment(endDate, "DD-MM-YYYY").add(1, "d").format(),
        Page: pageNumber <= 1 ? 0 : pageNumber - 1,
        Pagination: parseInt(filters.maxregisters),
      };

      var data = await SearchLog(json);

      if (data === undefined) {
        setData({});
        return;
      }
      setData(JSON.parse(data));
    } catch (error) {
      console.error(error);
    } finally {
      stop();
      setCanSearch(false);
    }
  };

  const handleOnPageChanged = (page) => {
    setPageNumber(page);
  };

  useEffect(() => {
    if (pageNumber !== null) {
      setCanSearch(true);
    }
  }, [pageNumber]);

  useEffect(
    (e) => {
      if (canSearch) fetchdata();
    },
    [canSearch]
  );

  const confDatePicker = {
    disableDays: [
      {
        from: moment(new Date()).toDate(),
        to: moment(new Date()).add(100000, "Y").toDate(),
      },
    ],
    dateFormat: "d-m-Y",
    locale: "es",
  };

  const headers = [
    { label: "Date", field: "Date" },
    { label: "Service", field: "Service" },
    { label: "Method", field: "Method" },
    { label: "User", field: "User" },
    { label: "Application", field: "Application" },
    {
      label: "Request/Response",
      field: "Request/Response",
      format: (e) => (
        <>
          <Button onClick={() => handleOpenModal(e)}>Abrir modal</Button>
        </>
      ),
    },
  ];

  const listItems = [
    { key: 1, Value: "25", Text: "25" },
    { key: 2, Value: "50", Text: "50" },
    { key: 3, Value: "75", Text: "75" },
    { key: 4, Value: "100", Text: "100" },
    { key: 5, Value: "150", Text: "150" },
    { key: 6, Value: "175", Text: "175" },
    { key: 7, Value: "200", Text: "200" },
  ];

  const tabs = [
    {
      Id: "request",
      Value: selectedModal?.Request,
      HeaderName: "Request",
      ButtonText: "Copy Clipboard",
      ButtonAction: () => copyToClipboard(selectedModal?.Request),
    },
    {
      Id: "response",
      Value: selectedModal?.Response,
      HeaderName: "Response",
      ButtonText: "Copy Clipboard",
      ButtonAction: () => copyToClipboard(selectedModal?.Response),
    },
  ];

  const paramsModal = {
    Id: "modalRequestResponse",
    Title: "Request/Response",
    ConfirmType: "submit",
    defaultTab: "request",
  };

  return (
    <>
      <Layout>
        <Layout center>
          <Layout.Item default="1/1">
            <Card element="article">
              <Form onSubmit={handleOnSubmit} autoComplete="off">
                <Fieldset>
                  <FormHeading
                    description={
                      data == null
                        ? ""
                        : data.LogItems?.length > 0
                        ? "Número de resultados: " + data?.Count
                        : "No hay resultados"
                    }
                  >
                    Búsqueda de logs de aplicaciones
                  </FormHeading>
                  <Fieldset.Row>
                    <Layout>
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/1"
                        id="user"
                        label="Usuario"
                        type="input"
                        onchange={handleOnChange}
                      />
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/3"
                        id="application"
                        label="Aplicación"
                        type="input"
                        onchange={handleOnChange}
                      />
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/3"
                        id="startDate"
                        label="Fecha desde"
                        type="datepicker"
                        onchange={(e) => setStartDate(e)}
                        confDatePicker={confDatePicker}
                        autoComplete={"off"}
                      />
                    </Layout>
                  </Fieldset.Row>
                  <Fieldset.Row>
                    <Layout>
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/3"
                        id="service"
                        label="Servicio"
                        type="input"
                        onchange={handleOnChange}
                      />
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/3"
                        id="method"
                        label="Método"
                        type="input"
                        onchange={handleOnChange}
                      />
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/3"
                        id="endDate"
                        label="Fecha hasta"
                        type="datepicker"
                        onchange={(e) => setEndDate(e)}
                        confDatePicker={confDatePicker}
                        autoComplete={"off"}
                      />
                    </Layout>
                  </Fieldset.Row>
                  <Fieldset.Row>
                    <Layout>
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/3"
                        id="maxregisters"
                        label="Nº Resultados"
                        type="select"
                        onchange={handleOnChange}
                        items={listItems}
                        placeholder="Numero registros"
                        defaultItem="25"
                      />
                    </Layout>
                  </Fieldset.Row>
                  <Fieldset>
                    <Fieldset.Row>
                      <ButtonContainer center>
                        <Button type="submit">Buscar</Button>
                        <Button type="reset" secondary>
                          Limpiar
                        </Button>
                      </ButtonContainer>
                    </Fieldset.Row>
                  </Fieldset>
                </Fieldset>
              </Form>
            </Card>
          </Layout.Item>
          <Layout.Item>
            {data?.LogItems?.length > 0 && (
              <Grid
                headers={headers}
                pageLimit={parseInt(filters.maxregisters)}
                rows={data?.LogItems}
                totalRecords={data?.Count}
                onPageChanged={handleOnPageChanged}
                loading={isLoading}
                totalPagesView={15}
                page={pageNumber}
                setcurrentpage={setPageNumber}
              ></Grid>
            )}
          </Layout.Item>
        </Layout>
      </Layout>
      {selectedModal !== null && (
        <ModalTabs
          params={paramsModal}
          tabs={tabs}
          handleCloseModal={() => {
            setSelectedModal(null);
          }}
        />
      )}
    </>
  );
};
