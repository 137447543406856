import React from "react";
import { Table, Spinner, Button, ButtonContainer } from "@vwfs-bronson/bronson-react";

export const GridTable = ({ headers, rows, rowFunctions = [], loading, primaryKey }) => {
    if (loading)
        return (
            <div className="u-text-center u-m">
                <Spinner></Spinner>
            </div>
        );

    return (
        <Table>
            <Table.Thead>
                <Table.Tr>
                    {headers.map((x, key) => (
                        <Table.Th key={`TableHeader_${key}`} field={x.field} sort={x.sort}>
                            {x.label}
                        </Table.Th>
                    ))}
                    {rowFunctions.length !== 0 && <th></th>}
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {rows?.map((row, index) => (
                    <Table.Tr key={`TableRow_${row.Nombre}_${index}`}>
                        {headers.map((header) => (
                            <Table.Td key={`TableRow_${row.Nombre}_${header.field}`}>
                                {header.format ? (
                                    <div className="c-table__cell-align">{header.format(index)}</div>
                                ) : (
                                    <div className="c-table__cell-align">{row[header.field]}</div>
                                )}
                            </Table.Td>
                        ))}
                        {rowFunctions.length !== 0 && (
                            <Table.Td key={`TableRow_${row.Nombre}_functions_${index}`}>
                                <ButtonContainer center>
                                    {rowFunctions.map(({ icon, func }, key) => (
                                        <Button
                                            style={{ height: "30px", minWidth: "30px" }}
                                            key={`TableRow_${row.Nombre}_${index}_Icon_${key}`}
                                            inContainer
                                            icon={icon.name}
                                            small
                                            round
                                            secondary
                                            onClick={() => func(row[primaryKey])}
                                        ></Button>
                                    ))}
                                </ButtonContainer>
                            </Table.Td>
                        )}
                    </Table.Tr>
                ))}
            </Table.Tbody>
        </Table>
    );
};