import { createContext } from "react";
import { useLoading } from "./../hooks/useLoading";

const LoadingContext = createContext();

export const LoadingContextProvider = ({ children }) => {
  const [{ isLoading }, { start, stop }] = useLoading(false);

  return (
    <>
      <LoadingContext.Provider value={{ isLoading, start, stop }}>
        {children}
      </LoadingContext.Provider>
    </>
  );
};

export default LoadingContext;
