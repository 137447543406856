export function sanitizeInput(value) {
  return value.trim();
}

export function addErrorToInput(id) {
  var element = document.getElementById(id);
  element.classList.add("is-error");
  element.setAttribute("aria-describedby", "error-" + id);
}

export function removeErrorToInput(id) {
  var element = document.getElementById(id);
  element.classList.remove("is-error");
  element.removeAttribute("aria-describedby");
}
export function copyToClipboard(props) { 
  
  navigator.clipboard.writeText(props);
}