import { createContext, useState, useEffect } from "react";
import { Login } from "../services/LoginService";
import { AuthCookie } from "./../services/AuthCookie";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import LoadingContext from "./LoadingContextProvider";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { start, stop } = useContext(LoadingContext);

  useEffect(() => {
    let token = AuthCookie.get("token");
    if (token) {
      setUser(token);
    }
  }, []);

  const [user, setUser] = useState(() => {
    let token = AuthCookie.get("token");
    if (token) {
      return token;
    }
    return null;
  });

  useEffect(() => {
    if (user) {
      navigate("/search");
    } else {
      navigate("/");
    }
  }, [user, navigate]);

  const login = async (payload) => {
    start();
    let apiResponse = await Login(payload);
    if (apiResponse) {
      AuthCookie.set("token", apiResponse.AccessToken, {
        expires: moment(apiResponse.AccessTokenExpiration).toDate(),
      });

      setUser(apiResponse.AccessToken);
    }
    stop();
  };
  return (
    <>
      <AuthContext.Provider value={{ user, login }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContext;
