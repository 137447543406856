import Cookies from "universal-cookie";
const authCookie = new Cookies();
export const AuthCookie = {
  set: function (cookieName, value, options) {
    authCookie.set(cookieName, value, options);
  },
  get: function (value) {
    return authCookie.get(value);
  },
};
