import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Layout, Button, ButtonContainer, Card, Form, FormHeading, Fieldset } from "@vwfs-bronson/bronson-react";
import { GridTable } from "./GridTable";
import usePagination from "../hooks/usePagination";

export const Grid = ({
    headers,
    rows,
    totalRecords,
    onSelectedRow,
    onPageChanged,
    pageLimit,
    loading,
    selectable = true,
    rowFunctions,
    primaryKey,
    page,
    setcurrentpage,
    totalPagesView = 4,
}) => {
    const { current, display } = usePagination(totalRecords,page, setcurrentpage, pageLimit, totalPagesView);
    const [rowsState, setRowsState] = useState(rows);

    useEffect(() => {
            onPageChanged(current);
    }, [current, onPageChanged]);

    useEffect(() => {
        if (rows) setRowsState(rows.map((x) => Object.assign(x, { checked: false })));
    }, [rows]);

    const handleOnSelectedRow = ({ id, checked }) => {
        if (selectable) {
            const newRows = rows.map((x) => Object.assign(x, { checked: false }));
            const index = rows.findIndex((x) => x.Id === id);
            if (index !== -1) newRows[index].checked = !checked;
            setRowsState(newRows);
            if (newRows[index].checked) onSelectedRow(id);
            else onSelectedRow(null);
        }
    };

    return (
        <>
            <GridTable
                headers={headers}
                rows={rowsState}
                selectable={selectable}
                loading={loading}
                onSelectedRow={handleOnSelectedRow}
                rowFunctions={rowFunctions}
                primaryKey={primaryKey}
            ></GridTable>
            <Layout center>
                <Layout.Item default="2/3">
                    {display}
                </Layout.Item>
                
            </Layout>
        
        </>
    );
};

Grid.propTypes = {
    headers: PropTypes.array,
    rows: PropTypes.array,
    totalRecords: PropTypes.number,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func,
    rowFunctions: PropTypes.array,
    selectable: PropTypes.bool,
};